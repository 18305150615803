import $ = require("jquery");

import { ILsApiAjaxSettings } from "./Api";
import LsApiUtilities from "./ApiUtilities";
import { LsEvents, ILsEventsData } from "~/Src/Components/Events/Events";
import LsLogger from "~/Src/Components/Logging/Logger";
import { LsModals, ILsModalOptions } from "~/Src/Components/Modal/Modal";
import LsRecaptcha from "~/Src/Components/Recaptcha/Recaptcha";
import LsFaceIdLogin from "~/Src/Components/FaceIdLogin/FaceIdLogin";

export interface ILsJsonResult {
    message?: string;
    parsingVersion?: "1.0";
    success?: boolean;
    sessionExpired?: boolean;
    events?: ILsEventsData | Array<ILsEventsData>;
    actions?: {
        redirect?: string;
        refresh?: boolean;
        submitForm?: {
            formId: string;
            tokenInput: string;
        };
        goNativeLogin?: {
            email: string;
            pw: string;
        };
    };
    notification?: {
        html: string;
    };
    modal?: {
        url: string;
    };
    html?: string;
    data?: {
        [key: string]: any;
    }
}

export class LsJsonResultManager {
    protected static _window: Window;
    protected static get window() {
        return LsJsonResultManager._window || (LsJsonResultManager._window = window);
    }

    protected static _document: Document;
    protected static get document() {
        return LsJsonResultManager._document || (LsJsonResultManager._document = document);
    }

    public static isJsonResult(result: unknown): result is ILsJsonResult {
        return LsJsonResultManager.isObject(result) && (!("parsingVersion" in result) || (result.parsingVersion === "1.0"));
    }

    public static run(result: ILsJsonResult, status: JQuery.Ajax.SuccessTextStatus, xhr: JQuery.jqXHR, settings?: ILsApiAjaxSettings) {
        if (result.events) {
            LsEvents.trigger(result.events);
        }

        if (result.actions?.goNativeLogin?.email && result.actions?.goNativeLogin?.pw) {
            LsLogger.log(`GoNativeLogin was in results, attempting to store faceId`, "info");

            LsFaceIdLogin.store(result.actions.goNativeLogin.email, result.actions.goNativeLogin.pw);
        }

        if (result.actions?.redirect) {
            LsJsonResultManager.window.location.href = result.actions.redirect;
            return;
        } else if (result.actions?.refresh) {
            LsJsonResultManager.window.location.reload(true);
            return;
        } else if (result.actions?.submitForm?.formId && result.actions?.submitForm?.tokenInput) {
            const $form = $(`#${result.actions.submitForm.formId}`);
            const $tokens = $("input[name='__RequestVerificationToken']");
            const $newToken = $(result.actions.submitForm.tokenInput).val();
            $tokens.val($newToken);
            $form.trigger("submit");
            return;
        }

        if (result.notification?.html) {
            $(LsJsonResultManager.document.body).append(result.notification.html);
        }

        if (result.modal?.url) {
            LsModals.openModalByUrl(result.modal.url);
        }

        if (result.html) {
            const $newHtml = $(result.html);
            const id = $newHtml.attr("id");
            if (id) {
                const $oldHtml = $(`#${id}`);
                if ($oldHtml.length > 0) {
                    $oldHtml.replaceWith($newHtml);

                    const $forms = $newHtml.find("form").addBack("form");
                    if ($forms.length > 0) {
                        for (const form of $forms.toArray()) {
                            const $form = $(form) as JQuery<HTMLFormElement>;
                            const validator: JQueryValidation.Validator = $form.data("validator");
                            if (!validator) {
                                $.validator.unobtrusive.parse($form);
                            }
                            const $recaptcha = $form.find("[data-recaptcha-options]");
                            if ($recaptcha.length > 0) {
                                LsRecaptcha.render($form);
                            }

                            const $loginFailed = $form.find("#lsLoginFailed");
                            if ($loginFailed.length > 0) {
                                LsFaceIdLogin.delete();
                            }
                        }
                    }

                    $(document).trigger("ajaxPageLoad");
                } else {
                    LsLogger.log(`LsJsonResultManager - element [#${id}] not found`, "fatal");
                    if (LsApiUtilities.global(settings)) {
                        LsModals.openErrorNotificationModal("An error has occurred.", "", { destroyOnClose: true });
                    }
                }
            } else {
                LsLogger.log(`LsJsonResultManager - response HTML does not have id [response=${LsLogger.excerpt(result.html, 200, false, true)}]`, "fatal");
                if (LsApiUtilities.global(settings)) {
                    LsModals.openErrorNotificationModal("An error has occurred.", "", { destroyOnClose: true });
                }
            }
        }
    }

    protected static isObject(obj: unknown): obj is { [key: string]: any } {
        return !Array.isArray(obj) && (typeof obj === "object");
    }
}